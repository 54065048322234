import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Loader from '../components/loader';

const HomePage = React.lazy(()=> import('./home'));
const TeamPage = React.lazy(()=> import('./Team'));
const TermsPage = React.lazy(()=> import('./Terms'));
const AboutUsPage = React.lazy(()=> import('./AboutUs'));
const PensionPage = React.lazy(()=>import('./Pension'));
const ProvidentPage = React.lazy(()=> import('./Provident'));
const OccupationalPage = React.lazy(()=> import('./Occupation'));
const PassPage = React.lazy(()=> import('./Paas'));
const PensionTechPage = React.lazy(()=> import('./PensionTech'));
const FAQPage = React.lazy(()=> import('./Faqs'));
const ContactUsPage = React.lazy(()=> import('./ContactUs'));
const PensionCalculatorPage = React.lazy(()=> import('./PensionCalculator'));
const GetStartedPage = React.lazy(()=> import('./GetStarted'));
const PrivacyPage = React.lazy(()=> import('./Privacy'));
const PortingForm = React.lazy(()=> import('./forms/PortingForm'));
const ClaimsForm = React.lazy(()=> import('./forms/ClaimsForm'));
const WithdrawalForm = React.lazy(()=> import('./forms/WithdrawalForm'));
const MemberenrollmentForm = React.lazy(()=> import('./forms/MemberEnrollmentForm'));
const EmployerEnrollmentForm = React.lazy(()=> import('./forms/EmployerEnrollmentForm'));
const EmployerEnrollmentFormAlt = React.lazy(()=> import('./forms/MemberEnrollmentFormAlt'));

const App = () => {
  return (
    <React.Suspense fallback={<Loader/>}>
      <Routes>
        <Route path='/' element={<HomePage/>}></Route>
        <Route path='/about' element={<AboutUsPage/>}></Route>
        <Route path='/team' element={<TeamPage/>}></Route>
        <Route path='/terms' element={<TermsPage/>}></Route>
        <Route path='/pension' element={<PensionPage/>}></Route>
        <Route path='/provident' element={<ProvidentPage/>}></Route>
        <Route path='/occupational' element={<OccupationalPage/>}></Route>
        <Route path='/paas' element={<PassPage/>}></Route>
        <Route path='/pension-tech' element={<PensionTechPage/>}></Route>
        <Route path='/faqs' element={<FAQPage/>}></Route>
        <Route path='/contact' element={<ContactUsPage/>}></Route>
        <Route path='/calculator' element={<PensionCalculatorPage/>}></Route>
        <Route path='/get-started' element={<GetStartedPage/>}></Route>
        <Route path='/privacy' element={<PrivacyPage/>}></Route>
        <Route path='/porting-form' element={<PortingForm/>}></Route>
        <Route path='/claims-form' element={<ClaimsForm/>}></Route>
        <Route path='/withdrawal-form' element={<WithdrawalForm/>}></Route>
        <Route path='/member-enrollment-form' element={<MemberenrollmentForm/>}></Route>
        <Route path='/employer-enrollment-form' element={<EmployerEnrollmentForm/>}></Route>
        <Route path='/employer-enrollment-form-alt' element={<EmployerEnrollmentFormAlt/>}></Route>
      </Routes>
    </React.Suspense>
  );
}

export default App;
